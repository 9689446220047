/* eslint import/no-dynamic-require: 0, global-require: 0 */

export default {
  functional: true,
  render(h, ctx) {
    return h(
      ctx.props.tag,
      {
        ...ctx.data,
        domProps: {
          innerHTML: `${require(`!html-loader!@/assets/img/icons/${ctx.props.icon}.svg`)}`,
        },
      },
      ctx.children
    );
  },
  name: 'SvgIcon',
  props: {
    tag: {
      type: String,
      default: () => 'i',
    },
    icon: {
      type: [String, Number],
      required: true,
    },
  },
};
